import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NavLinks from "../components/navigation/NavLinks.js";
import ProfilePicture from "../components/sidebar/ProfilePicture.js";
import SidebarBlocks from "../components/sidebar/SidebarBlocks.js";
// import Banner from "../components/sidebar/Banner";
import Homepage from "../pages/Homepage";
import Projects from "../pages/ProjectsPage";
import NotFound404 from "../pages/404";

class Wrapper extends Component {
	render() {
		return (
			<div>
				<aside>
					<ProfilePicture />
					<SidebarBlocks />
					{/* <Banner /> */}
				</aside>

				<main>
					<NavLinks />
					<section>
						<Switch>
							<Route exact path="/" component={Homepage} />
							<Route exact path="/projects" component={Projects} />
							<Route path="*" component={NotFound404} />
						</Switch>
					</section>
				</main>
			</div>
		);
	}
}

export default Wrapper;
