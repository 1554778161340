import { useTranslation } from "react-i18next";

function NotFound404() {
	const { t } = useTranslation();
	return (
		<section>
			<h2>{t("notFound.content")}</h2>
		</section>
	);
}

export default NotFound404;
