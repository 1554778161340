import { useTranslation } from "react-i18next";

function ViewProject({ projectLink, githubLink }) {
	const { t } = useTranslation();
	return (
		<div className="listprojects__card__content__buttons">
			{projectLink && (
				<a
					href={projectLink}
					rel="noopener noreferrer nofollow"
					target="_blank"
				>
					{t("projects.view")}
				</a>
			)}
			{/* {showGithub(githubLink)} */}
			{githubLink && (
				<a href={githubLink} rel="noopener noreferrer nofollow" target="_blank">
					Github
				</a>
			)}
		</div>
	);
}

export default ViewProject;
