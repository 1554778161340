import { useTranslation } from "react-i18next";
import PageHeading from "../Heading/PageHeading";

function ProfileIntro() {
	const { t } = useTranslation();

	return (
		<section className="profile">
			<PageHeading
				heading={t("profile.heading")}
				content={t("profile.content")}
			/>
		</section>
	);
}

export default ProfileIntro;
