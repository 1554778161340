import { useTranslation } from "react-i18next";

function ListEducation() {
  const { t } = useTranslation();
  const data = t("education.educationData", { returnObjects: true });

  return (
    <section className="education py-1">
      <h2>{t("education.heading")}</h2>
      <hr />

      {data.map((obj) => (
        <div className="education__experience py-1">
          <div className="education__experience--big-child">
            <h3>{obj.education}</h3>
            {obj.education == "Johns Hopkins University" ? (
              <p>Web development</p>
            ) : null}
          </div>
          <div className="education__experience--small-child">
            <h3>{obj.timeframe}</h3>
          </div>
        </div>
      ))}
    </section>
  );
}

export default ListEducation;
