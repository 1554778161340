import profilepicture from "../../img/romero.png";

function ProfilePicture() {
	return (
		<section>
			<div>
				<img src={profilepicture} alt="profilepicture" />
			</div>
			<div>
				<h1>Romero Lijkwan</h1>
				<p>09-12-1982</p>
			</div>
		</section>
	);
}

export default ProfilePicture;
