import ProjectsIntro from "../components/main-projects/ProjectsIntro";
import ListProjects from "../components/main-projects/ListProjects";

function Projects(props) {
	return (
		<>
			<ProjectsIntro />
			<ListProjects />
		</>
	);
}

export default Projects;
