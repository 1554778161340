import { useTranslation } from "react-i18next";

function SidebarBlocks() {
	const { t } = useTranslation();

	return (
		<section>
			<div>
				<h4>Contact</h4>
				<div>
					<p>
						<a href="mailto:romerolijkwan@gmail.com">romero@wslm.nl</a>
					</p>
					<p>
						<a href="tel:+31613497113">+31 613 49 7113</a>
					</p>
				</div>
			</div>

			<div>
				<h4>Profiles</h4>
				<div>
					<p>
						<a
							href="https://linkedin.com/in/romerolijkwan"
							target="_blank"
							rel="noopener noreferrer nofollow"
						>
							<span>LinkedIn.com</span>
						</a>
					</p>
					<p>
						<a
							href="https://trailblazer.me/id/romson"
							target="_blank"
							rel="noopener noreferrer nofollow"
						>
							<span>Salesforce.com</span>
						</a>
					</p>
				</div>
			</div>

			<div>
				<h4>{t("languages.heading")}</h4>
				<div>
					<p>- {t("languages.dutch")}</p>
					<p>- {t("languages.english")}</p>
				</div>
			</div>

			<div>
				<h4>{t("proudOf.heading")}</h4>
				<div>
					<p>{t("proudOf.content")}</p>
					<p className="aoa-autoverzekeringen">
						<span>AOA autoverzekeringen</span>
					</p>
				</div>
			</div>
		</section>
	);
}

export default SidebarBlocks;
