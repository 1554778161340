import { useTranslation } from "react-i18next";

function WorkExperience() {
  const { t } = useTranslation();
  const data = t("listWork.workData", { returnObjects: true });

  return (
    <section className="work py-1">
      <h2>{t("listWork.heading")}</h2>
      <hr />

      {data.map((obj) => (
        <>
          <div className="work__experience py-1">
            <div className="work__experience--big-child">
              <h3>{obj.position}</h3>
              <p>({obj.timeframe})</p>
            </div>

            <div className="work__experience--employer">
              <h3>{obj.employer}</h3>
            </div>
          </div>

          <div className="work__experience--list-items">
            <ul>
              <li>{obj.bulletpoints[0]}</li>
              <li>{obj.bulletpoints[1]}</li>
              <li>{obj.bulletpoints[2]}</li>
            </ul>
          </div>
        </>
      ))}
    </section>
  );
}

export default WorkExperience;
