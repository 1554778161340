function PageHeading({ heading, content }) {
	return (
		<>
			<h2>{heading}</h2>
			<p className="py-1">{content}</p>
		</>
	);
}

export default PageHeading;
