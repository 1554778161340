import ProfileIntro from "../components/main-homepage/ProfileIntro";
import ListSkills from "../components/main-homepage/ListSkills";
import WorkExperience from "../components/main-homepage/WorkExperience";
import ListEducation from "../components/main-homepage/ListEducation";

function Homepage(props) {
	return (
		<>
			<ProfileIntro />
			<ListSkills />
			<WorkExperience />
			<ListEducation />
		</>
	);
}

export default Homepage;
