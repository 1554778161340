export const SkillsData = [
  "Javascript",
  "HTML5",
  "CSS3",
  "React",
  "Vue.js",
  "Node.js",
  "Express",
  "SQL",
  "Bootstrap",
  "UI design",
  "Salesforce",
  "Git",
  "Github/GitLab",
  "Jira",
  "WordPress",
  "Google Analytics",
  "Google Ads",
  "Google Tag Manager",
  "Splunk",
  "Angular",
  "Python",
];
