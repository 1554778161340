import { useTranslation } from "react-i18next";
import PageHeading from "../Heading/PageHeading";

function ProjectsIntro() {
	const { t } = useTranslation();

	return (
		<section className="projects">
			<PageHeading
				heading={t("projects.heading")}
				content={t("projects.content")}
			/>
		</section>
	);
}

export default ProjectsIntro;
