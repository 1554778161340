import { SkillsData } from "../../data/skillsData";
import Skill from "./Skill";

const Skills = () => {
	return (
		<section className="skills py-1">
			<h2>Skills</h2>
			<div className="skills__items">
				{SkillsData.map((skill) => (
					<Skill key={skill} name={skill} />
				))}
			</div>
		</section>
	);
};

export default Skills;
