import { useTranslation } from "react-i18next";
import ViewProject from "./ViewProject";
import library from "../../img/library.jpg";
import todo from "../../img/todo.jpg";
import upload from "../../img/upload.jpg";
import portfolio from "../../img/portfolio.jpg";
import attention4 from "../../img/attention4.jpg";
import bulk4 from "../../img/bulk4.jpg";
import loikidz from "../../img/loikidz.jpg";
import moon from "../../img/moon.jpg";

function ListProjecs() {
	const { t } = useTranslation();
	const data = t("projects.projectsData", { returnObjects: true });

	return (
		<section className="listprojects">
			{data.map((obj) => (
				<div className="listprojects__card">
					<div className="listprojects__card__image">
						<img src={obj.src} alt="library" />
					</div>
					<div className="listprojects__card__content">
						<h3>{obj.projectName}</h3>
						<p>{obj.projectInfo}</p>
						<ViewProject
							projectLink={obj.projectLink}
							githubLink={obj.githubLink}
						/>
					</div>
				</div>
			))}
		</section>
	);
}

export default ListProjecs;
